export enum RedirectUriServerErrors {
  REDIRECT_URI_LIMIT_EXCEEDED = 'REDIRECT_URI_LIMIT_EXCEEDED',
  REDIRECT_URI_MIN_LIMIT = 'REDIRECT_URI_MIN_LIMIT',
  REDIRECT_URI_INVALID_URL = 'REDIRECT_URI_INVALID_URL',
  REDIRECT_URI_INVALID_PROTOCOL = 'REDIRECT_URI_INVALID_PROTOCOL',
  REDIRECT_URI_INVALID_HOST = 'REDIRECT_URI_INVALID_HOST',
  REDIRECT_URI_WILDCARD_HOST = 'REDIRECT_URI_WILDCARD_HOST',
  REDIRECT_URI_INVALID_WILDCARD_HOST = 'REDIRECT_URI_INVALID_WILDCARD_HOST',
  REDIRECT_URI_MISMATCH = 'REDIRECT_URI_MISMATCH'
}
