import { globalStore } from '~/stores';
import logger from '../logger';

type UserDetails = {
  email?: string;
  firstName?: string;
  lastName?: string;
  workspaceDisplayName?: string;
};

export function submitHubspotForm(userDetails: UserDetails) {
  const callSdkToSubmit = () => {
    const formData = {
      firstName: userDetails.firstName || '',
      lastName: userDetails.lastName || '',
      email: userDetails.email || '',
      company: userDetails.workspaceDisplayName || ''
    };
    if (globalStore.debugModeEnabled || import.meta.env.MODE === 'staging') {
      logger.info('Hubspot Form data:', formData);
    } else {
      window.skApi.hubspot.signup(
        formData,
        function (err) {
          logger.info(err, 'Failed to submit Hubspot signup form');
        },
        function (data) {
          logger.info(data, 'Hubspot signup form submitted');
        }
      );
    }
  };
  //check if the script is already present
  if (window.skApi?.hubspot?.signup) {
    callSdkToSubmit();
  } else {
    const skIntegrationJs =
      'https://cdn.scalekit.cloud/scalekit-website/scripts/sk-integrations.js?v=0.0.1';
    const skIntegrationScript = document.createElement('script');
    skIntegrationScript.setAttribute('src', skIntegrationJs);
    skIntegrationScript.onload = () => {
      callSdkToSubmit();
    };
    document.head.appendChild(skIntegrationScript);
  }
}
