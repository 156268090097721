export interface GenerateWebhookPortalLinkResponse {
  url: string;
}

export interface WebhookEndpointData {
  id: string;
  url: string;
  description: string;
  channels: string[] | null;
  disabled: boolean;
  filterTypes: string[] | null;
  metadata: Record<string, any>;
  rateLimit: number | null;
  uid: string | null;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export interface WebhookEventTypeData {
  name: string;
  description: string;
  featureFlag: string | null;
  schemas: Record<any, any> | null;
  deprecated: boolean;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum WebhookEvents {
  DIR_ENABLED = 'organization.directory_enabled',
  DIR_DISABLED = 'organization.directory_disabled',
  DIR_GROUP_CREATED = 'organization.directory.group_created',
  DIR_GROUP_UPDATED = 'organization.directory.group_updated',
  DIR_GROUP_DELETED = 'organization.directory.group_deleted',
  DIR_USER_CREATED = 'organization.directory.user_created',
  DIR_USER_UPDATED = 'organization.directory.user_updated',
  DIR_USER_DELETED = 'organization.directory.user_deleted',
  ORG_SSO_ENABLED = 'organization.sso_enabled',
  ORG_SSO_DISABLED = 'organization.sso_disabled'
}
