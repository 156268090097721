import { createRenderEffect, JSXElement, mergeProps } from 'solid-js';
import Prism from 'prismjs';
import { CodeBlockLanguages } from '~/components/CodeBlock/CodeBlock.tsx';
import './prism-theme.scss';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-java';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

type PrimsBlockProps = {
  content: string;
  language: CodeBlockLanguages;
  showLineNumbers?: boolean;
};

export default function PrimsBlock(props: PrimsBlockProps): JSXElement {
  const merged = mergeProps(
    {
      showLoneNumbers: false
    },
    props
  );

  // const getCodeTokens = (code: string, language: CodeBlockLanguages) => {
  //   return Prism.highlight(code, Prism.languages[language], language);
  // };

  createRenderEffect(() => {
    setTimeout(() => {
      Prism.highlightAll();
    });
  });

  return (
    <pre
      classList={{
        'line-numbers': merged.showLineNumbers
      }}
    >
      {/*<code innerHTML={getCodeTokens(merged.content, merged.language)}></code>*/}
      <code
        class={`language-${merged.language} leading-loose font-code tracking-wider`}
      >
        {merged.content}
      </code>
    </pre>
  );
}
