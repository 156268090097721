import { getFormattedDate, getLocalizedString } from '~/i18n/utils.ts';
import { ColumnDef } from '@tanstack/solid-table';
import { DirectoryEventTypes, EventData } from '~/api/types/eventData.ts';
import { DirectoryStats } from '~/api/types/directoryData.ts';
import { miscConstants } from '~/consts.ts';
import { getEmailFromDirectoryUserPayload } from '~/pages/DirectorySync/directoryDataUtils.ts';

const DIRECTORY_EVENT_LIST_PAGE_SIZE = miscConstants.DEFAULT_TABLE_PAGE_SIZE;

const getActivityText = (event: EventData) => {
  switch (event.type) {
    case DirectoryEventTypes.DIR_USER_CREATED:
      return `${getEmailFromDirectoryUserPayload(event.data)} was created`;
    case DirectoryEventTypes.DIR_USER_UPDATED:
      return `${getEmailFromDirectoryUserPayload(event.data)} was updated`;
    case DirectoryEventTypes.DIR_USER_DELETED:
      return `${getEmailFromDirectoryUserPayload(event.data)} was deleted`;
    case DirectoryEventTypes.DIR_GROUP_CREATED:
      return `${event.data.displayName} was created`;
    case DirectoryEventTypes.DIR_GROUP_UPDATED:
      return `${event.data.displayName} was updated`;
    case DirectoryEventTypes.DIR_GROUP_DELETED:
      return `${event.data.displayName} was deleted`;
    case DirectoryEventTypes.DIR_DIR_ENABLED:
      return `Directory created`;
    case DirectoryEventTypes.DIR_DIR_DISABLED:
      return `Directory unlinked`;
  }
  return '';
};

const getEventDataColumnDef = ({
  intl
}: {
  intl: any;
}): ColumnDef<EventData>[] => [
  {
    header: getLocalizedString(`table_headers.event_name`, { intl }),
    cell: (info) => (
      <div class={`flex flex-col`}>
        <div>{info.row.original.type as string}</div>
        <div class={`mt-1 text-xs text-fg-muted`}>
          {getActivityText(info.row.original)}
        </div>
      </div>
    ),
    meta: {
      class: 'w-9/12' // additional meta to control column width
    }
  },
  {
    header: getLocalizedString(`table_headers.event_occurred_at`, {
      intl
    }),
    accessorKey: 'occurredAt',
    cell: (info) => (
      <div class={`h-full`}>
        {info.getValue()
          ? getFormattedDate(info.getValue() as string, { includeTime: true })
          : ''}
      </div>
    ),
    meta: {
      class: 'w-3/12' // additional meta to control column width
    }
  }
];

const isDirectoryUserEvent = (event: EventData): boolean =>
  [
    DirectoryEventTypes.DIR_USER_CREATED,
    DirectoryEventTypes.DIR_USER_UPDATED,
    DirectoryEventTypes.DIR_USER_DELETED
  ].includes(event.type as DirectoryEventTypes);

const isDirectoryGroupEvent = (event: EventData): boolean =>
  [
    DirectoryEventTypes.DIR_GROUP_CREATED,
    DirectoryEventTypes.DIR_GROUP_UPDATED,
    DirectoryEventTypes.DIR_GROUP_DELETED
  ].includes(event.type as DirectoryEventTypes);

const getEventObjectType = (event: EventData) => {
  if (isDirectoryUserEvent(event)) {
    return 'directory_user';
  }
  if (isDirectoryGroupEvent(event)) {
    return 'directory_group';
  }
  return 'directory_event';
};

const doesDirectoryHaveUpdatedUserData = (
  oldStats: DirectoryStats,
  newStats: DirectoryStats
): boolean =>
  newStats.totalUsers !== oldStats.totalUsers ||
  (!!newStats.userUpdatedAt &&
    (!oldStats.userUpdatedAt ||
      new Date(newStats.userUpdatedAt) > new Date(oldStats.userUpdatedAt)));

const doesDirectoryHaveUpdatedGroupData = (
  oldStats: DirectoryStats,
  newStats: DirectoryStats
): boolean =>
  newStats.totalGroups !== oldStats.totalGroups &&
  !!newStats.groupUpdatedAt &&
  (!oldStats.groupUpdatedAt ||
    new Date(newStats.groupUpdatedAt) > new Date(oldStats.groupUpdatedAt));

const doesDirectoryHaveUpdatedData = (
  oldStats: DirectoryStats,
  newStats: DirectoryStats
) =>
  doesDirectoryHaveUpdatedUserData(oldStats, newStats) ||
  doesDirectoryHaveUpdatedGroupData(oldStats, newStats);

export {
  DIRECTORY_EVENT_LIST_PAGE_SIZE,
  getEventDataColumnDef,
  getEventObjectType,
  isDirectoryUserEvent,
  isDirectoryGroupEvent,
  doesDirectoryHaveUpdatedUserData,
  doesDirectoryHaveUpdatedGroupData,
  doesDirectoryHaveUpdatedData
};
