import { ApiConfigData, EnvironmentData, OrganizationData } from '~/api';
import { getEnvironmentUrl } from '~/pages/ApiCredentials/utils.tsx';
import { ResourceContextData } from '~/api/types/resourceContextData.ts';

const getTranslationKey = (key: string) => `pages.quick_start.${key}`;

export enum QuickStartModuleTypes {
  SSO = 'sso'
  // SCIM = 'scim'
}

const getInitiateSsoOauthAuthorizeUrl = ({
  environment,
  client,
  organization
}: {
  environment: EnvironmentData;
  client: ApiConfigData;
  organization: OrganizationData;
}) => {
  return `${getEnvironmentUrl(environment)}/oauth/authorize?response_type=code&client_id=${client.keyId}&redirect_uri=${client.defaultRedirectUri}&scope=openid profile email&organization_id=${organization.id}`;
};

const getModuleKey = (
  moduleType: QuickStartModuleTypes
): keyof ResourceContextData | null => {
  switch (moduleType) {
    case QuickStartModuleTypes.SSO:
      return 'ssoOnboardingDone';
    default:
      return null;
  }
};

const isQuickStartForModuleExplored = (
  resourceContextData: ResourceContextData,
  moduleType: QuickStartModuleTypes
) => {
  return getModuleKey(moduleType)
    ? resourceContextData[getModuleKey(moduleType)!]
    : false;
};

const isQuickStartNotExploredYet = (
  resourceContextData: ResourceContextData
) => {
  return Object.values(QuickStartModuleTypes).some((moduleType) => {
    return !isQuickStartForModuleExplored(resourceContextData, moduleType);
  });
};

export {
  getTranslationKey,
  getInitiateSsoOauthAuthorizeUrl,
  getModuleKey,
  isQuickStartForModuleExplored,
  isQuickStartNotExploredYet
};
