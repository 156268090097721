import styles from '~/components/Layout/layout.module.scss';
import LeftNavGroup from '~/components/Layout/LeftNav/LeftNavGroup.tsx';
import {
  getAppEnvironmentsNavConfig,
  getAppWorkspaceNavConfig,
  LeftNavType
} from '~/components/Layout/LeftNav/utils.tsx';
import ScalekitLogo from '~/assets/scalekit-logo-dark.svg';
import { useAppData } from '~/components/AppData';
import { EnvironmentData } from '~/api';
import { mergeProps, Show } from 'solid-js';
import BackNavigation from '~/components/Layout/LeftNav/BackNavigation.tsx';
import { useResourceContextData } from '~/components/ResourceContextData/ResourceContextData.tsx';

type LeftNavProps = {
  type: LeftNavType;
};
export default function LeftNav(props: LeftNavProps) {
  const merged = mergeProps({}, props);
  const appData = useAppData();
  const resourceContextData = useResourceContextData();

  const getLeftNavConfig = () => {
    switch (merged.type) {
      case LeftNavType.ENVIRONMENT:
        return getAppEnvironmentsNavConfig(
          appData.selectedEnvironment as EnvironmentData,
          resourceContextData.data
        );
      case LeftNavType.WORKSPACE:
        return getAppWorkspaceNavConfig();
    }
  };

  return (
    <div class={styles.leftNavContainer}>
      <Show when={merged.type === LeftNavType.WORKSPACE}>
        <BackNavigation />
      </Show>
      <LeftNavGroup groups={getLeftNavConfig()} />
      <div class={`mt-auto px-2`}>
        <img
          alt={`Scalekit Logo`}
          src={ScalekitLogo}
        />
      </div>
    </div>
  );
}
