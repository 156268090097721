import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  Show,
  untrack
} from 'solid-js';
import Tab from '~/components/ui/Tab';
import './codeblock.scss';
import PrimsBlock from '~/components/CodeBlock/PrimsBlock.tsx';
import { Button } from '~/components/ui/Button';
import { CheckCircleIcon, CopyIcon } from 'lucide-solid';
import { copyTextIntoClipboard } from '~/utils/commonUtils.ts';
import { globalStore, setGlobalStore } from '~/stores';
import { miscConstants } from '~/consts.ts';

export enum CodeBlockLanguages {
  JAVASCRIPT = 'javascript',
  PYTHON = 'py',
  JAVA = 'java',
  GO = 'go',
  BASH = 'BASH',
  XML = 'xml'
}

export const LANGUAGE_DISPLAY_NAMES = {
  [CodeBlockLanguages.JAVASCRIPT]: 'Node.js',
  [CodeBlockLanguages.PYTHON]: 'Python',
  [CodeBlockLanguages.JAVA]: 'Java',
  [CodeBlockLanguages.GO]: 'Go',
  [CodeBlockLanguages.XML]: 'XML',
  [CodeBlockLanguages.BASH]: 'Bash'
};

type LanguageConfig = {
  language: CodeBlockLanguages;
  content: string;
  languageSelectionTracker: CodeBlockLanguages;
  enabled?: boolean;
  displayName?: string;
  showLineNumbers?: boolean;
};

type CodeEmbedProps = {
  languageConfigs: LanguageConfig[];
};

export default function CodeBlock(props: CodeEmbedProps): JSXElement {
  const merged = mergeProps({}, props);

  if (!merged.languageConfigs.length) {
    throw new Error('languageConfigs array cannot be empty');
  }

  const filterSelectedLanguage = () =>
    merged.languageConfigs.find(
      ({ enabled, languageSelectionTracker }) =>
        !!enabled ||
        languageSelectionTracker === globalStore.codeBlockSelectedLanguage
    );

  const [selectedLanguage, setSelectedLanguage] = createSignal<LanguageConfig>(
    filterSelectedLanguage() ?? merged.languageConfigs[0]
  );

  createEffect(() => {
    if (globalStore.codeBlockSelectedLanguage) {
      untrack(() => {
        const language = filterSelectedLanguage();
        language && setSelectedLanguage(language);
      });
    }
  });

  const [copied, setCopied] = createSignal<boolean>(false);
  let timerValue: ReturnType<typeof setTimeout>;

  createEffect(() => {
    if (copied()) {
      clearTimeout(timerValue);
      timerValue = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  });

  const copyClickHandler = (e: Event, content: string) => {
    e.stopPropagation();
    copyTextIntoClipboard(content).then(() => !copied() && setCopied(true));
  };

  const tabList = merged.languageConfigs.map(
    ({
      language,
      languageSelectionTracker,
      content,
      displayName,
      showLineNumbers
    }) => ({
      id: languageSelectionTracker,
      label: displayName ?? LANGUAGE_DISPLAY_NAMES[language],
      content: () => (
        <div class={`w-full relative`}>
          <div class={`absolute right-2 -top-[60px] z-10`}>
            <Show
              when={!copied()}
              fallback={
                <CheckCircleIcon
                  width={18}
                  class={`text-success`}
                />
              }
            >
              <Button
                variant={'link'}
                class={` text-fg-subtle hover:text-bg-default`}
                onClick={(e) => copyClickHandler(e, content)}
              >
                <CopyIcon width={18} />
              </Button>
            </Show>
          </div>

          <PrimsBlock
            language={language}
            content={content}
            showLineNumbers={showLineNumbers}
          />
        </div>
      )
    })
  );

  return (
    <div class={`code-block-container`}>
      <Tab
        lazyMount={true}
        list={tabList}
        value={selectedLanguage().languageSelectionTracker}
        onValueChange={({ value }) => {
          localStorage.setItem(
            miscConstants.localStorageKeys.getCodeBlockLanguageKey(),
            value
          );
          setGlobalStore(
            'codeBlockSelectedLanguage',
            value as CodeBlockLanguages
          );
        }}
      />
    </div>
  );
}
