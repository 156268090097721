import { getRequestConfig, withMakeRequest } from '~/api';
import Request from '~/api/Request.ts';
import { ResourceContextData } from '~/api/types/resourceContextData.ts';

type GetContextsRequest = {
  environmentId: string;
};

export type GetContextsResponse = {
  context: ResourceContextData;
};

const getContexts: (
  request: GetContextsRequest
) => Promise<ResourceContextData> = withMakeRequest(
  async ({ environmentId }: GetContextsRequest) =>
    await Request.getInstance().get(
      `/environments/${environmentId}/contexts`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: GetContextsResponse) => data.context
);

type SetContextsRequest = {
  environmentId: string;
  data: Partial<ResourceContextData>;
};

export type SetContextsResponse = {
  context: ResourceContextData;
};

const setContexts: (
  request: SetContextsRequest
) => Promise<ResourceContextData> = withMakeRequest(
  async ({ environmentId, data }: SetContextsRequest) =>
    await Request.getInstance().put(
      `/environments/${environmentId}/contexts`,
      data,

      getRequestConfig({ addEnvDomain: true })
    )
);

export { getContexts, setContexts };
