import { ConnectionData } from '~/api/types/connectionData';
import logger from '../logger';

export enum ClientNotificationEventTypes {
  SSO_CONNECTION_ENABLED = 'ORGANIZATION_SSO_ENABLED',
  SSO_CONNECTION_DISABLED = 'ORGANIZATION_SSO_DISABLED'
}

type ConnectionNotificationData = Pick<
  ConnectionData,
  'id' | 'type' | 'provider' | 'enabled' | 'status'
> & { connection_type?: string };

export type ConnectionNotificationPayload = {
  event_type: ClientNotificationEventTypes;
  object: string;
  data: ConnectionNotificationData;
  organization_id?: string;
};

export const postClientNotificationMessage = (
  message: ConnectionNotificationPayload
) => {
  try {
    window.parent?.postMessage(message, '*');
  } catch (error) {
    logger.error('Error posting iframe message to parent window', error);
  }
};
