import { HttpStatusCode } from 'axios';
import {
  ApiConfigData,
  ApiErrorInstance,
  CustomDomainStatuses,
  EnvironmentData,
  isApi5xxResponseStatus,
  MemberData,
  MemberListResponse,
  withHandleError
} from '~/api';
import { getLocalizedString } from '~/i18n/utils.ts';
import { RedirectUriServerErrors } from '~/pages/ApiCredentials/ServerErrors.ts';

export enum RedirectUriFlowTypes {
  SSO_QUICK_START = 'SSO_QUICK_START',
  API_CONFIG = 'API_CONFIG'
}

const getTranslationKey = (key: string): string =>
  `pages.api_credentials.${key}`;

const getApiCredentialsFromResponse = (
  apiCredentials: ApiConfigData[] | null | undefined
): ApiConfigData => {
  if (!apiCredentials?.length) {
    throw new Error('Failed to fetch API Credentials');
  }
  // Take the first index for now
  // as there can be only one api credential for an environment
  return apiCredentials[0];
};

const getMemberMapFromResponse = (
  memberResponse: MemberListResponse | undefined
): Record<string, MemberData> => {
  return !memberResponse
    ? {}
    : memberResponse.members.reduce(
        (memberObject: Record<string, MemberData>, member: MemberData) => {
          if (member.id) {
            memberObject[member.id] = member;
          }
          return memberObject;
        },
        {}
      );
};

function getEnvironmentDomain(env: EnvironmentData) {
  return (
    (env.customDomainStatus === CustomDomainStatuses.ACTIVE &&
      env.customDomain) ||
    env.domain
  );
}

function getEnvironmentUrl(env: EnvironmentData) {
  return `https://${getEnvironmentDomain(env)}`;
}

const serverErrorBasePath = `server_errors.api_credentials`;

const handleAddRedirectUriError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  const genericErrorKey = `add_redirect_uri_failed`;
  if (isApi5xxResponseStatus(serverError.httpStatus)) {
    return getLocalizedString(`${serverErrorBasePath}.${genericErrorKey}`, {
      intl
    });
  }
  if (serverError.httpStatus === HttpStatusCode.BadRequest) {
    const errorCode = serverError.error?.details?.[0]?.error_code;
    const errorKey = Object.values(RedirectUriServerErrors).includes(errorCode)
      ? errorCode.toLowerCase()
      : genericErrorKey;

    return getLocalizedString(`${serverErrorBasePath}.${errorKey}`, {
      intl
    });
  }
});

export {
  getTranslationKey,
  getApiCredentialsFromResponse,
  getMemberMapFromResponse,
  getEnvironmentUrl,
  getEnvironmentDomain,
  handleAddRedirectUriError
};
