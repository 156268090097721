import { Component, JSXElement } from 'solid-js';
import { Localized } from '~/i18n';
import {
  ArrowLeftRightIcon,
  Building2Icon,
  FileKey2Icon,
  GemIcon,
  KeyRoundIcon,
  LayoutGridIcon,
  MouseIcon,
  RocketIcon,
  UserRoundIcon,
  WebhookIcon,
  ZapIcon
} from 'lucide-solid';
import {
  EnvironmentPagePath,
  getSlug,
  MyAccountPagePath,
  replacePathParams,
  WorkspacePagePath
} from '~/components/AppRouter/utils.ts';
import { EnvironmentData, EnvironmentTypes } from '~/api';
import { FeatureFlagKeys } from '~/api/types/featureFlagData.ts';
import { isFeatureEnabled } from '~/utils/features/featureFlag.tsx';
import { SINGLE_SIGNON_TAB_TYPES } from '~/pages/Authentication/SingleSignOn/utils.ts';
import { DIRECTORY_SYNC_TAB_TYPES } from '~/pages/Authentication/DirectorySync/utils.ts';
import { isQuickStartNotExploredYet } from '~/pages/Quickstart/utils.ts';
import { ResourceContextData } from '~/api/types/resourceContextData.ts';

export type LeftNavLinkConfig = {
  iconComponent?: Component;
  labelComponent: Component;
  href: string;
  hidden?: boolean;
};

export type LeftNavGroupConfig = {
  labelComponent?: Component;
  items: Array<LeftNavLinkConfig>;
};

export enum LeftNavType {
  WORKSPACE = 'WORKSPACE',
  ENVIRONMENT = 'ENVIRONMENT'
}

const getLabelComponent = ({
  translationKey,
  upperCase = false,
  iconComponent,
  className = ''
}: {
  translationKey: string;
  upperCase?: boolean;
  iconComponent?: Component<{
    width: number;
    height: number;
    strokeWidth?: number;
    class?: string;
  }>;
  className?: string;
}): Component => {
  return (): JSXElement => {
    const IconComponent = iconComponent;
    return (
      <div class={`flex items-center`}>
        {IconComponent && (
          <IconComponent
            width={20}
            height={20}
            strokeWidth={1}
            class={`me-2`}
          />
        )}
        <span class={className}>
          <Localized
            upperCase={upperCase}
            translationKey={translationKey}
          />
        </span>
      </div>
    );
  };
};

const getMenuItemLabelTranslationPath = (translationStr: string) =>
  `page_titles.${translationStr}`;

const getConfigurationGroup = (
  selectedEnvironment: EnvironmentData
): Array<LeftNavGroupConfig> => {
  const selectedEnvironmentId = selectedEnvironment?.id;
  const configurationGroup: LeftNavGroupConfig = {
    labelComponent: getLabelComponent({
      translationKey: 'left_nav_group_labels.configuration',
      upperCase: true
    }),
    items: []
  };
  if (isFeatureEnabled(FeatureFlagKeys.PORTAL_CUSTOMIZATION)) {
    const customizationItem: LeftNavLinkConfig = {
      iconComponent: MouseIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`customization`),
        className: 'text-fg-muted'
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        EnvironmentPagePath.ENVIRONMENT_CUSTOMIZATIONS,
        true
      )
    };
    configurationGroup.items.push(customizationItem);
  }
  if (isFeatureEnabled(FeatureFlagKeys.WEBHOOKS)) {
    const webhookItem: LeftNavLinkConfig = {
      iconComponent: WebhookIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`webhooks`),
        className: 'text-fg-muted'
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        EnvironmentPagePath.ENVIRONMENT_WEBHOOKS,
        true
      )
    };
    configurationGroup.items.push(webhookItem);
  }
  return (
    configurationGroup.items.length ? [configurationGroup] : []
  ) as LeftNavGroupConfig[];
};

const getAuthenticationGroup = (
  selectedEnvironment: EnvironmentData
): Array<LeftNavGroupConfig> => {
  const selectedEnvironmentId = selectedEnvironment?.id;
  const authenticationGroup: LeftNavGroupConfig = {
    labelComponent: getLabelComponent({
      translationKey: 'left_nav_group_labels.authentication',
      upperCase: true
    }),
    items: []
  };

  if (isFeatureEnabled(FeatureFlagKeys.SOCIAL_CONNECTION)) {
    authenticationGroup.items.push({
      iconComponent: LayoutGridIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`social_login`),
        className: 'text-fg-muted'
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        EnvironmentPagePath.ENVIRONMENT_SOCIAL_CONNECTIONS,
        true
      ),
      hidden: !isFeatureEnabled(FeatureFlagKeys.SOCIAL_CONNECTION)
    });
  }

  if (isFeatureEnabled(FeatureFlagKeys.SINGLE_SIGNON)) {
    authenticationGroup.items.push({
      iconComponent: KeyRoundIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`sso`),
        className: 'text-fg-muted'
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        replacePathParams(EnvironmentPagePath.ENVIRONMENT_SINGLE_SIGN_ON, {
          tabType: !isFeatureEnabled(FeatureFlagKeys.QUICK_START)
            ? SINGLE_SIGNON_TAB_TYPES.QUICKSTART
            : SINGLE_SIGNON_TAB_TYPES.ATTRIBUTES
        }) as EnvironmentPagePath,
        true
      )
    });
  }

  if (isFeatureEnabled(FeatureFlagKeys.DIRECTORY_SYNC)) {
    authenticationGroup.items.push({
      iconComponent: ArrowLeftRightIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`directory_sync`),
        className: 'text-fg-muted'
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        replacePathParams(EnvironmentPagePath.ENVIRONMENT_DIRECTORY_SYNC, {
          tabType: DIRECTORY_SYNC_TAB_TYPES.QUICKSTART
        }) as EnvironmentPagePath,
        true
      )
    });
  }

  return (
    authenticationGroup.items.length ? [authenticationGroup] : []
  ) as LeftNavGroupConfig[];
};

const getAppEnvironmentsNavConfig = (
  selectedEnvironment: EnvironmentData,
  resrouceContextData: ResourceContextData
): Array<LeftNavGroupConfig> => {
  const selectedEnvironmentId = selectedEnvironment?.id;

  return [
    {
      items: [
        {
          iconComponent: ZapIcon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`quick_start`),
            className: 'text-fg-muted'
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIROMNEMT_QUICK_START,
            true
          ),
          hidden:
            !isFeatureEnabled(FeatureFlagKeys.QUICK_START) ||
            selectedEnvironment.type !== EnvironmentTypes.DEVELOPMENT ||
            !isQuickStartNotExploredYet(resrouceContextData)
        },
        {
          iconComponent: RocketIcon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`getting_started`),
            className: 'text-fg-muted'
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIROMNEMT_GETTING_STARTED,
            true
          ),
          hidden: import.meta.env.VITE_GETTING_STARTED_ENABLED !== 'true'
        },
        {
          iconComponent: Building2Icon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`organization`),
            className: 'text-fg-muted'
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIROMNEMT_ORGANIZATIONS,
            true
          )
        },
        {
          iconComponent: UserRoundIcon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`users`),
            className: 'text-fg-muted'
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIROMNEMT_USERS,
            true
          ),
          hidden: !isFeatureEnabled(FeatureFlagKeys.USERS)
        }
      ]
    },
    ...getAuthenticationGroup(selectedEnvironment),
    ...getConfigurationGroup(selectedEnvironment),
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.developer_settings',
        upperCase: true
      }),
      items: [
        {
          iconComponent: FileKey2Icon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`api_credentials`),
            className: 'text-fg-muted'
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIRONMENT_API_CREDENTIALS,
            true
          )
        }
      ]
    }
  ];
};

const getAppWorkspaceNavConfig = (): Array<LeftNavGroupConfig> => {
  return [
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.workspace',
        upperCase: false,
        iconComponent: GemIcon,
        className: 'text-sm'
      }),
      items: (() => {
        const items: LeftNavLinkConfig[] = [
          {
            labelComponent: getLabelComponent({
              translationKey:
                getMenuItemLabelTranslationPath(`workspace_general`)
            }),
            href: getSlug.workspace(WorkspacePagePath.WORKSPACE_GENERAL, true)
          },
          {
            labelComponent: getLabelComponent({
              translationKey:
                getMenuItemLabelTranslationPath(`manage_environments`)
            }),
            href: getSlug.workspace(
              WorkspacePagePath.WORKSPACE_MANAGE_ENVIRONMENTS,
              true
            )
          },
          {
            labelComponent: getLabelComponent({
              translationKey: getMenuItemLabelTranslationPath(`members`)
            }),
            href: getSlug.workspace(WorkspacePagePath.WORKSPACE_MEMBERS, true)
          }
        ];
        if (isFeatureEnabled(FeatureFlagKeys.BILLING)) {
          items.push({
            labelComponent: getLabelComponent({
              translationKey: getMenuItemLabelTranslationPath(`billing`)
            }),
            href: getSlug.workspace(WorkspacePagePath.WORKSPACE_BILLING, true)
          });
        }
        return items;
      })()
    },
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.my_account',

        upperCase: false,
        iconComponent: UserRoundIcon,
        className: 'text-sm'
      }),
      items: [
        {
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`my_profile`)
          }),
          href: getSlug.myAccount(MyAccountPagePath.MYACCOUNT_PROFILE, true)
        }
      ]
    }
  ];
};

export { getAppEnvironmentsNavConfig, getAppWorkspaceNavConfig };
