import { ApiErrorInstance, withHandleError } from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';
import { SocialProviders } from '~/api/types/connectionData.ts';

const RESEND_DELAY_IN_SEC_PER_ATTEMPT = [15, 30];
const MAX_ALLOWED_RESEND_ATTEMPTS = 2;
const WAITING_TIME_BEFORE_RESEND_PER_ATTEMPT = [15, 0];

export enum LoginSteps {
  ENTER_EMAIL = 'ENTER_EMAIL',
  ENTER_OTP = 'ENTER_OTP'
}

const MIN_SEARCH_CHARS = 3;

const TRANSLATION_BASE_PATH = `pages.login`;

const initiateSocialLogin = (provider: SocialProviders) => {
  window.location.assign(`/app/login/authorize?provider=${provider}`);
};

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

const handleLoginErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  const translationBasePath = 'server_errors.login';
  if (serverError.httpStatus === HttpStatusCode.NotFound) {
    return getLocalizedString(`${translationBasePath}.not_found`, {
      intl
    });
  }
});

const MESSAGE_CODE_TOO_MANY_LOGIN_ATTEMPTS = 'TOO_MANY_LOGIN_ATTEMPTS';
const handleValidateOtpErrors = withHandleError(({ error, intl, params }) => {
  const serverError = error as ApiErrorInstance;
  const translationBasePath = 'server_errors.login';
  if (serverError.httpStatus === HttpStatusCode.BadRequest) {
    return getLocalizedString(`${translationBasePath}.invalid_otp`, {
      intl
    });
  } else if (serverError.httpStatus === HttpStatusCode.TooManyRequests) {
    params?.onTooManyAttempt?.();
    return MESSAGE_CODE_TOO_MANY_LOGIN_ATTEMPTS;
  }
});

const LOGIN_OTP_LENGTH = 6;

const isMagicOtpEnabled = () =>
  import.meta.env.VITE_MAGIC_OTP_ENABLED === 'true';

export {
  RESEND_DELAY_IN_SEC_PER_ATTEMPT,
  MAX_ALLOWED_RESEND_ATTEMPTS,
  WAITING_TIME_BEFORE_RESEND_PER_ATTEMPT,
  MIN_SEARCH_CHARS,
  getTranslationKey,
  handleLoginErrors,
  initiateSocialLogin,
  LOGIN_OTP_LENGTH,
  isMagicOtpEnabled,
  handleValidateOtpErrors,
  MESSAGE_CODE_TOO_MANY_LOGIN_ATTEMPTS
};
