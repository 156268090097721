import {
  createEffect,
  JSXElement,
  Match,
  mergeProps,
  Show,
  Switch,
  untrack
} from 'solid-js';
import Tab from '~/components/ui/Tab';
import { useNavigate, useParams } from '@solidjs/router';
import { getConnectionListUrl } from '~/pages/Authentication/Connections/utils.ts';
import { Localized } from '~/i18n';
import {
  getTranslationKey,
  shouldDisplayAdminPortalTab
} from '~/pages/AdminPortal/utils.ts';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import { getDirectoryListUrl } from '~/pages/DirectorySync/utils.ts';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import { isLoadedInIframe, withPortalAuthToken } from '~/utils/commonUtils.ts';
import withOrganizationContext from '~/pages/AdminPortal/AdminPortalWithOrganizationContext.tsx';
import {
  getFlatEnabledFeaturesArrayFromOrgFeatures,
  getPortalFeaturesDisplayNames,
  isFeatureEnabledForOrg
} from '~/utils/features/featureFlag.tsx';
import { FeatureFlagKeys } from '~/api/types/featureFlagData.ts';
import { TabListType } from '~/components/ui/Tab/Tab.tsx';
import { createQuery } from '@tanstack/solid-query';
import {
  getOrganizationById,
  getOrganizationDetailsKey,
  OrganizationData,
  retryAuthorizedRequests
} from '~/api';
import SkLoader from '~/components/ui/SkLoader';
import Alert from '~/components/ui/Alert';
import AdminPortalNoFeaturesState from '~/pages/AdminPortal/AdminPortalNoFeaturesState.tsx';

type AdminPortalTabListProps = {
  children: JSXElement;
};

export default function AdminPortalTabList(
  props: AdminPortalTabListProps
): JSXElement {
  const merged = mergeProps({}, props);
  const params = useParams();
  const navigate = useNavigate();

  /*
   * @todo move it to the top of admin portal in some context
   */
  const organizationDetailsQuery = createQuery(() => ({
    queryKey: getOrganizationDetailsKey(params.organizationId),
    queryFn: async () => {
      return await getOrganizationById(params.organizationId);
    },
    retry: retryAuthorizedRequests
  }));

  const getTabUrl = (tabType: OrgDetailsDataType): string => {
    if (tabType === OrgDetailsDataType.DIRECTORIES) {
      return getDirectoryListUrl({ params });
    } else if (tabType === OrgDetailsDataType.SSO_CONNECTIONS) {
      return getConnectionListUrl({ params });
    }
    throw new Error(AppErrors.INVALID_PORTAL_TYPE_IN_URL);
  };

  const getTabComponent = (
    organization: OrganizationData,
    tabType: OrgDetailsDataType
  ) =>
    withOrganizationContext(
      () => <>{merged.children}</>,
      organization,
      tabType
    );

  const getTabList = (organization) => {
    const tabList: TabListType[] = [];

    if (
      isFeatureEnabledForOrg(organization, FeatureFlagKeys.SINGLE_SIGNON) &&
      shouldDisplayAdminPortalTab(OrgDetailsDataType.SSO_CONNECTIONS)
    ) {
      tabList.push({
        id: OrgDetailsDataType.SSO_CONNECTIONS,
        label: (
          <span class={`text-sm`}>
            <Localized translationKey={getTranslationKey('tab_titles.sso')} />
          </span>
        ),
        content: getTabComponent(
          organizationDetailsQuery.data!,
          OrgDetailsDataType.SSO_CONNECTIONS
        )
      });
    }

    if (
      isFeatureEnabledForOrg(organization, FeatureFlagKeys.DIRECTORY_SYNC) &&
      shouldDisplayAdminPortalTab(OrgDetailsDataType.DIRECTORIES)
    ) {
      tabList.push({
        id: OrgDetailsDataType.DIRECTORIES,
        label: (
          <span class={`text-sm`}>
            <Localized
              translationKey={getTranslationKey('tab_titles.dir_sync')}
            />
          </span>
        ),
        content: getTabComponent(
          organizationDetailsQuery.data!,
          OrgDetailsDataType.DIRECTORIES
        )
      });
    }

    return tabList;
  };

  createEffect(() => {
    if (organizationDetailsQuery.isSuccess && organizationDetailsQuery.data) {
      untrack(() => {
        if (
          params.portalType === OrgDetailsDataType.SSO_CONNECTIONS &&
          !(
            isFeatureEnabledForOrg(
              organizationDetailsQuery.data!,
              FeatureFlagKeys.SINGLE_SIGNON
            ) && shouldDisplayAdminPortalTab(OrgDetailsDataType.SSO_CONNECTIONS)
          ) &&
          isFeatureEnabledForOrg(
            organizationDetailsQuery.data!,
            FeatureFlagKeys.DIRECTORY_SYNC
          ) &&
          shouldDisplayAdminPortalTab(OrgDetailsDataType.DIRECTORIES)
        ) {
          navigate(
            withPortalAuthToken(getTabUrl(OrgDetailsDataType.DIRECTORIES))
          );
        } else if (
          params.portalType === OrgDetailsDataType.DIRECTORIES &&
          !(
            isFeatureEnabledForOrg(
              organizationDetailsQuery.data!,
              FeatureFlagKeys.DIRECTORY_SYNC
            ) && shouldDisplayAdminPortalTab(OrgDetailsDataType.DIRECTORIES)
          ) &&
          isFeatureEnabledForOrg(
            organizationDetailsQuery.data!,
            FeatureFlagKeys.SINGLE_SIGNON
          ) &&
          shouldDisplayAdminPortalTab(OrgDetailsDataType.SSO_CONNECTIONS)
        ) {
          navigate(
            withPortalAuthToken(getTabUrl(OrgDetailsDataType.SSO_CONNECTIONS))
          );
        }
      });
    }
  });

  return (
    <>
      <Switch>
        <Match when={organizationDetailsQuery.isPending}>
          <SkLoader />
        </Match>
        <Match
          when={
            organizationDetailsQuery.isSuccess && organizationDetailsQuery.data
          }
        >
          {(() => {
            const tabList = getTabList(organizationDetailsQuery.data);
            const enabledOrgFeatures =
              getFlatEnabledFeaturesArrayFromOrgFeatures(
                organizationDetailsQuery.data?.settings?.features ?? []
              );
            const isAtleastOneFeatureToDisplay = enabledOrgFeatures.some(
              (feature) => {
                // @todo add more tabs in needed in future
                if (feature === FeatureFlagKeys.SINGLE_SIGNON) {
                  return shouldDisplayAdminPortalTab(
                    OrgDetailsDataType.SSO_CONNECTIONS
                  );
                }
                if (feature === FeatureFlagKeys.DIRECTORY_SYNC) {
                  return shouldDisplayAdminPortalTab(
                    OrgDetailsDataType.DIRECTORIES
                  );
                }
                return false;
              }
            );
            return (
              <>
                <Show
                  when={isAtleastOneFeatureToDisplay}
                  fallback={<AdminPortalNoFeaturesState />}
                >
                  <Show when={!isLoadedInIframe()}>
                    <div class={`-mt-8 mb-4 font-normal text-sm text-fg-muted`}>
                      <Alert variant={'info'}>
                        <Localized
                          translationKey={getTranslationKey(
                            'header.welcome_note'
                          )}
                          vars={{
                            features: getPortalFeaturesDisplayNames(
                              getFlatEnabledFeaturesArrayFromOrgFeatures(
                                organizationDetailsQuery.data?.settings
                                  ?.features ?? []
                              )
                            ).join(' and '),
                            organizationName:
                              organizationDetailsQuery.data!.displayName
                          }}
                        />
                      </Alert>
                    </div>
                  </Show>
                </Show>
                <Show when={tabList.length > 1}>
                  <Tab
                    lazyMount={true}
                    list={tabList}
                    value={params.portalType}
                    onValueChange={({ value }) => {
                      // required for the routeful tabs
                      navigate(
                        withPortalAuthToken(
                          getTabUrl(value as OrgDetailsDataType)
                        )
                      );
                    }}
                  />
                </Show>
                <Show
                  when={
                    tabList.length === 1 &&
                    tabList[0].id === OrgDetailsDataType.SSO_CONNECTIONS
                  }
                >
                  {(() => {
                    const SSOComponent = getTabComponent(
                      organizationDetailsQuery.data!,
                      OrgDetailsDataType.SSO_CONNECTIONS
                    );
                    return <SSOComponent />;
                  })()}
                </Show>

                <Show
                  when={
                    tabList.length === 1 &&
                    tabList[0].id === OrgDetailsDataType.DIRECTORIES
                  }
                >
                  {(() => {
                    const DirSyncComponent = getTabComponent(
                      organizationDetailsQuery.data!,
                      OrgDetailsDataType.DIRECTORIES
                    );
                    return <DirSyncComponent />;
                  })()}
                </Show>
              </>
            );
          })()}
        </Match>
      </Switch>
    </>
  );
}
