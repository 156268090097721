import { WebhookEvents } from '~/api/types/webhookData.ts';

export enum DirectoryEventTypes {
  DIR_USER_CREATED = WebhookEvents.DIR_USER_CREATED,
  DIR_USER_UPDATED = WebhookEvents.DIR_USER_UPDATED,
  DIR_USER_DELETED = WebhookEvents.DIR_USER_DELETED,
  DIR_GROUP_CREATED = WebhookEvents.DIR_GROUP_CREATED,
  DIR_GROUP_UPDATED = WebhookEvents.DIR_GROUP_UPDATED,
  DIR_GROUP_DELETED = WebhookEvents.DIR_GROUP_DELETED,
  DIR_DIR_ENABLED = WebhookEvents.DIR_ENABLED,
  DIR_DIR_DISABLED = WebhookEvents.DIR_DISABLED
}

export interface EventData {
  id: string;
  type: string;
  occurredAt: string;
  data: Record<any, any>;
  rawPayload?: string;
}

export interface EventsListResponse {
  prevPageToken: string;
  nextPageToken: string;
  totalSize: number;
  events: Array<EventData>;
}
