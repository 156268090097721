import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import { globalStore } from '~/stores';

const TRANSLATION_BASE_PATH = `admin_portals`;

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

enum OrgFeaturesQueryParamValues {
  SSO = 'sso',
  DIRECTORIES = 'dir_sync'
}

const getOrgDetailsModuleForFeaturesQueryParamValue = (
  param: OrgFeaturesQueryParamValues
): OrgDetailsDataType | '' => {
  if (param === OrgFeaturesQueryParamValues.SSO) {
    return OrgDetailsDataType.SSO_CONNECTIONS;
  } else if (param === OrgFeaturesQueryParamValues.DIRECTORIES) {
    return OrgDetailsDataType.DIRECTORIES;
  }
  return '';
};

const getPortalFeaturesToDisplayFromQueryParams = (): OrgDetailsDataType[] => {
  const urlSearchParams = new URLSearchParams(location.search);
  const allowedModuleTypes = new Set(Object.values(OrgDetailsDataType));

  return urlSearchParams.get('features')
    ? (urlSearchParams
        .get('features')!
        .split(',')
        .map(
          (moduleType) =>
            getOrgDetailsModuleForFeaturesQueryParamValue(
              moduleType as OrgFeaturesQueryParamValues
            ) as OrgDetailsDataType
        )
        .filter((moduleType) =>
          allowedModuleTypes.has(moduleType as OrgDetailsDataType)
        ) as OrgDetailsDataType[])
    : Object.values(OrgDetailsDataType);
};

const shouldDisplayAdminPortalTab = (tabType: OrgDetailsDataType) =>
  globalStore.adminPortalEnabledModulesFromQueryParam.includes(tabType);

export {
  getTranslationKey,
  getPortalFeaturesToDisplayFromQueryParams,
  shouldDisplayAdminPortalTab
};
