import { Component, JSXElement, mergeProps } from 'solid-js';
import Link from '~/components/Link';

type WorkspaceSwitcherMenuItemProps = {
  iconComponent: Component;
  label: JSXElement;
  href?: string;
};

type IconProps = {
  strokeWidth?: number;
  width?: number;
  class?: string;
};

export default function WorkspaceSwitcherMenuItem(
  props: WorkspaceSwitcherMenuItemProps
): JSXElement {
  const merged = mergeProps(
    {
      href: '#'
    },
    props
  );
  const IconComponent: (p: IconProps) => JSXElement = merged.iconComponent;
  return (
    <Link
      class={`flex items-center font-normal text-fg-default w-full h-full`}
      href={merged.href}
    >
      <IconComponent
        class={`mr-2 text-fg-default w-5 h-5`}
        strokeWidth={1}
      />
      {merged.label}
    </Link>
  );
}
