export enum INTL_LOG_MODES {
  SILENT = 0,
  INFO = 1,
  ERROR = 2
}

export const CLIENT_SECRET_HASH_LENGTH = 64;

const SEC_IN_MILLIS = 1000;
const MIN_IN_MILLIS = 60 * SEC_IN_MILLIS;
const HOUR_IN_MILLIS = 60 * MIN_IN_MILLIS;
const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;
const MONTH_IN_MILLIS = 30 * DAY_IN_MILLIS;
const YEAR_IN_MILLIS = 365 * DAY_IN_MILLIS;
const CUSTOM_SAML_DOC_LINK = 'https://docs.scalekit.com/integrations/saml';
const CUSTOM_OIDC_DOC_LINK = 'https://docs.scalekit.com/integrations/oidc';

const CONSTANTS = {
  colors: { orange: 'orange-400', white: 'white' },
  devConfig: {
    intlLogMode: INTL_LOG_MODES.SILENT // For regular development, let it be set to INFO
  },
  externalLinks: {
    supportEmail: 'support',
    supportEmailDomain: 'scalekit.com',
    termsAndConditions: 'https://www.scalekit.com/legal/terms-of-service',
    privacyPolicy: 'https://www.scalekit.com/legal/privacy-policy',
    oauthGuidePlaceholder: 'https://docs.scalekit.com/',
    websiteBookDemoUrl: 'https://www.scalekit.com/demo',
    ssoDocumentationSiteLink: 'https://docs.scalekit.com/',
    ssoSampleAppLink: 'https://github.com/scalekit-inc/sso-express-example',
    idpInitatedSsoDocLink:
      'https://docs.scalekit.com/sso/guides/setup-sso/implement-idp-initiated-sso',
    redirectUriDocLink:
      'https://docs.scalekit.com/sso/guides/key-concepts/redirect-uri',
    kbArticles: {
      root: 'https://docs.scalekit.com',
      environmentDetails: 'https://docs.scalekit.com',
      clientSecret:
        'https://docs.scalekit.com/best-practices/manage-client-secrets',
      redirectUris: 'https://docs.scalekit.com/best-practices/redirect-uri',
      domainAddAndVerify: '',
      customerPortalHelpLink: '',
      mockIdpIntegrationGuide: 'https://docs.scalekit.com/sso/test-sso',
      singleSignonQuickStartGuide:
        'https://docs.scalekit.com/sso/in-product-quickstart',
      directorySyncQuickStartGuide:
        'https://docs.scalekit.com/scim/in-product-quickstart'
    },
    idpIntegrationDocLinks: {
      saml: {
        okta: 'https://docs.scalekit.com/integrations/okta-saml',
        onelogin: 'https://docs.scalekit.com/integrations/onelogin-saml',
        google: CUSTOM_SAML_DOC_LINK,
        microsoftAd: 'https://docs.scalekit.com/integrations/azure-ad-saml',
        pingIdentity: CUSTOM_SAML_DOC_LINK,
        jumpcloud: 'https://docs.scalekit.com/integrations/jumpcloud-saml',
        custom: CUSTOM_SAML_DOC_LINK
      },
      oidc: {
        okta: CUSTOM_OIDC_DOC_LINK,
        onelogin: CUSTOM_OIDC_DOC_LINK,
        google: CUSTOM_OIDC_DOC_LINK,
        microsoftAd: CUSTOM_OIDC_DOC_LINK,
        pingIdentity: CUSTOM_OIDC_DOC_LINK,
        jumpcloud: CUSTOM_OIDC_DOC_LINK,
        custom: CUSTOM_OIDC_DOC_LINK
      },
      scim: {
        okta: 'https://docs.scalekit.com',
        onelogin: 'https://docs.scalekit.com',
        microsoftAd: 'https://docs.scalekit.com',
        jumpcloud: 'https://docs.scalekit.com',
        pingIdentity: 'https://docs.scalekit.com',
        custom: 'https://docs.scalekit.com'
      }
    },
    socialConnectionGuideLinks: {
      google:
        'https://docs.scalekit.com/integrations/social-connections/google',
      github:
        'https://docs.scalekit.com/integrations/social-connections/github',
      gitlab:
        'https://docs.scalekit.com/integrations/social-connections/gitlab',
      microsoft:
        'https://docs.scalekit.com/integrations/social-connections/microsoft',
      salesforce:
        'https://docs.scalekit.com/integrations/social-connections/salesforce',
      linkedin:
        'https://docs.scalekit.com/integrations/social-connections/linkedin'
    }
  },
  appConfig: {
    apiRetries: 2,
    maxSupportedClientSecrets: 2,
    maxSupportedRedirectUtis: 5
  },
  timeSignatures: {
    SEC_IN_MILLIS,
    MIN_IN_MILLIS,
    HOUR_IN_MILLIS,
    DAY_IN_MILLIS,
    MONTH_IN_MILLIS,
    YEAR_IN_MILLIS
  },
  localStorageKeys: {
    getEnvironmentSelection: () => `e_s_item`,
    getTestConnectionKey: (connectionId: string) => `t_c_r_${connectionId}`,
    getCodeBlockLanguageKey: () => `c_b_l_k`
  },
  MESSAGE_TYPES: { TEST_SSO_CONNCETION_RESULTS: 'TEST_SSO_CONNCETION_RESULTS' },
  QUERY_PARAMS: {
    CUSTOMER_PORTAL_AUTH_TOKEN: 'token',
    CUSTOMER_PORTAL_LINK_ID: 'link_id'
  },
  supportEmail: 'support',
  supportEmailDomain: 'scalekit.com',
  samlAllowedCertificateExtensions: ['.pem', '.crt', '.cer', '.cert', '.key'],
  DEFAULT_TABLE_PAGE_SIZE: 20
};

const {
  colors: appColors,
  devConfig,
  externalLinks,
  appConfig,
  timeSignatures,
  ...miscConstants
} = CONSTANTS;

export {
  appColors,
  devConfig,
  externalLinks,
  appConfig,
  timeSignatures,
  miscConstants
};
