import { withMakeRequest, WorkspaceData } from '~/api';
import Request from '~/api/Request.ts';
import { AppBaseNamespaces } from '~/components/AppRouter/utils.ts';

const login: (email: string) => Promise<WorkspaceData> = withMakeRequest(
  async (email) =>
    await Request.getInstance().post(`${window.location.origin}/app/login`, {
      email,
      redirectUri: `${window.location.origin}${AppBaseNamespaces.WORKSPACE}/`
    })
);

const validateOtp: (email: string, otp: string) => Promise<WorkspaceData> =
  withMakeRequest(
    async (email, otp) =>
      await Request.getInstance().post(
        `${window.location.origin}/app/login/otp`,
        {
          email,
          otp
        }
      )
  );
const logoutMember: () => Promise<boolean> = withMakeRequest(
  async () =>
    await Request.getInstance().post(`${window.location.origin}/app/logout`),
  () => true
);

export { login, validateOtp, logoutMember };
