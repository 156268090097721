import {
  BookOpenIcon,
  HelpCircleIcon,
  LogOutIcon,
  SettingsIcon,
  ZapIcon
} from 'lucide-solid';
import { Localized } from '~/i18n';
import styles from './header.module.scss';
import NamedIcon from '~/components/ui/NamedIcon';
import {
  EnvironmentSwitcher,
  WorkspaceSwitcher
} from 'src/components/Layout/Header/SwitcherButtons';
import { useAppData } from '~/components/AppData';
import LogoutButton from '~/components/Layout/Header/LogoutButton.tsx';
import Menu from '~/components/ui/Menu';
import Link from '~/components/Link';
import {
  EnvironmentPagePath,
  getSlug,
  MyAccountPagePath
} from '~/components/AppRouter/utils.ts';
import { EnvironmentTypes } from '~/api';
import { externalLinks } from '~/consts.ts';
import { mergeEmailParts } from '~/utils/commonUtils';
import ScalekitCautionWarning from '~/components/Layout/Header/ScalekitCautionWarning.tsx';
import { Show } from 'solid-js';
import { isFeatureEnabled } from '~/utils/features/featureFlag.tsx';
import { FeatureFlagKeys } from '~/api/types/featureFlagData.ts';
import { isQuickStartNotExploredYet } from '~/pages/Quickstart/utils.ts';
import { useResourceContextData } from '~/components/ResourceContextData/ResourceContextData.tsx';

const supportEmail = mergeEmailParts([
  externalLinks.supportEmail,
  externalLinks.supportEmailDomain
]);

export default function Header() {
  const appData = useAppData();
  const resourceContextData = useResourceContextData();

  return (
    <header
      class={`${styles.topNavbar}`}
      classList={{
        'border-b-yellow-500':
          appData.selectedEnvironment?.type !== EnvironmentTypes.PRODUCTION,
        'border-b-green-600':
          appData.selectedEnvironment?.type === EnvironmentTypes.PRODUCTION
      }}
    >
      <div class={styles.leftSection}>
        {/*<div class={styles.logoContainer}>*/}
        {/*  <NamedIcon*/}
        {/*    w={32}*/}
        {/*    name={appData.workspace?.displayName || 'W'}*/}
        {/*  />*/}
        {/*</div>*/}
        <div class="flex items-center">
          <WorkspaceSwitcher>
            <div class={`flex items-center`}>
              <NamedIcon
                w={30}
                name={appData.workspace?.displayName || 'Workspace Name'}
                class={`mr-2 ${styles.workspaceLogo}`}
              />
              {appData.workspace?.displayName}
            </div>
          </WorkspaceSwitcher>
          <span class={`mx-1.5`}> / </span>
          <EnvironmentSwitcher />
        </div>
      </div>
      <ScalekitCautionWarning />
      <div class={`flex items-center`}>
        <nav class={`flex items-center`}>
          <Show
            when={
              isFeatureEnabled(FeatureFlagKeys.QUICK_START) &&
              appData.selectedEnvironment?.type ===
                EnvironmentTypes.DEVELOPMENT &&
              !isQuickStartNotExploredYet(resourceContextData.data)
            }
          >
            <button
              class={`mr-2 px-2 py-1 text-sm font-normal hover:bg-bg-muted rounded-md transition-colors delay-75`}
              role={`link`}
            >
              <a
                href={getSlug.environmentById(
                  appData.selectedEnvironment!.id,
                  EnvironmentPagePath.ENVIROMNEMT_QUICK_START,
                  true
                )}
                class={`flex items-center`}
              >
                <ZapIcon
                  width={14}
                  class={`mr-1`}
                  strokeWidth={2}
                />
                <Localized translationKey={`cta_labels.quickstart`} />
              </a>
            </button>
          </Show>
          <button
            class={`mr-2 px-2 py-1 text-sm font-normal hover:bg-bg-muted rounded-md transition-colors delay-75`}
            role={`link`}
          >
            {/* @todo implement help functionality */}
            <a
              href={`mailto:${supportEmail}}`}
              class={`flex items-center`}
            >
              <HelpCircleIcon
                width={14}
                class={`mr-1`}
                strokeWidth={2}
              />
              <Localized translationKey={`cta_labels.help`} />
            </a>
          </button>
          <button
            class={`mr-4 px-2 py-1 text-sm font-normal hover:bg-bg-muted rounded-md transition-colors delay-75`}
            role={`link`}
          >
            <a
              href={externalLinks.kbArticles.root}
              class={`flex items-center`}
              target={'_blank'}
            >
              <BookOpenIcon
                width={14}
                class={`mr-1 mt-0.5`}
                strokeWidth={2}
              />
              <Localized translationKey={`cta_labels.documentation`} />
            </a>
          </button>

          <Menu
            id={`workspace-switcher`}
            groups={[
              {
                label: (
                  <div
                    class={`flex flex-col text-left text-sm font-normal text-fg-muted`}
                  >
                    <div class={`text-fg-muted`}>
                      {appData.member?.firstName} {appData.member?.lastName}
                    </div>
                    <div>{appData.member?.email}</div>
                  </div>
                ),
                items: [
                  {
                    label: (
                      <Link
                        class={`flex items-center font-normal w-full h-full`}
                        href={getSlug.myAccount(
                          MyAccountPagePath.MYACCOUNT_PROFILE,
                          true
                        )}
                      >
                        <SettingsIcon
                          class={`mr-2 w-5 h-5`}
                          strokeWidth={1}
                        />
                        <Localized
                          translationKey={'page_titles.profile_settings'}
                        />
                      </Link>
                    )
                  },
                  {
                    label: (
                      <LogoutButton class={`h-full`}>
                        <LogOutIcon
                          class={`mr-2 w-5 h-5`}
                          strokeWidth={1}
                        />
                        <Localized translationKey={'page_titles.logout'} />
                      </LogoutButton>
                    )
                  }
                ]
              }
            ]}
            containerClass={'p-4'}
          >
            <NamedIcon
              w={32}
              rounded
              name={appData.member?.firstName}
              class={`cursor-pointer bg-fg-default`}
            />
          </Menu>
        </nav>
      </div>
    </header>
  );
}
