import {
  AnyAppPath,
  AppBaseNamespaces,
  EnvironmentPagePath,
  getSlug,
  PORTAL_BASE_SLUG,
  replacePathParams
} from '~/components/AppRouter/utils.ts';
import { Params, useMatch } from '@solidjs/router';
import { ZodError } from 'zod';
import { globalStore, setGlobalStore } from '~/stores';
import { CLIENT_SECRET_HASH_LENGTH, miscConstants } from '~/consts.ts';
import { initPosthog } from '~/utils/productLogging/posthog.ts';
import { initSentry } from '~/utils/productLogging/sentry.ts';
import { initClarity } from '~/utils/productLogging/clarity';
import logger from '~/utils/logger';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { jwtDecode } from 'jwt-decode';
import { getPortalFeaturesToDisplayFromQueryParams } from '~/pages/AdminPortal/utils.ts';
import { ClientSecretData } from '~/api';

enum AppContexts {
  SCALEKIT_DASHBOARD = 'SCALEKIT_DASHBOARD',
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
  HANDLERS = 'HANDLERS'
}

function getAppContext(): AppContexts {
  const pathName = new URL(window.location.href).pathname;
  if (pathName.startsWith(`${AppBaseNamespaces.WORKSPACE}/`)) {
    return AppContexts.SCALEKIT_DASHBOARD;
  } else if (pathName.startsWith(`${PORTAL_BASE_SLUG}/`)) {
    return AppContexts.CUSTOMER_PORTAL;
  } else if (pathName.startsWith(`${AppBaseNamespaces.HANDLERS}/`)) {
    return AppContexts.HANDLERS;
  }
  throw new Error('Invalid namespace');
}

function getRandomInteger(min: number, max: number): number {
  // Using Math.floor to round down to the nearest integer
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function searchInListByKeys(
  list: { [key: string]: any }[],
  keys: string[],
  search: string
): Array<object> {
  return list.filter((item) => {
    return keys.some(
      (k) => !!item[k] && item[k].toLowerCase().includes(search.toLowerCase())
    );
  });
}

const flattenObject = (
  input: Record<string, any>,
  keyName?: string,
  delimeter: string = '.'
): Record<string, any> => {
  let result: Record<string, any> = {};

  for (const key in input) {
    const newKey = keyName ? `${keyName}${delimeter}${key}` : key;

    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      result = { ...result, ...flattenObject(input[key], newKey, delimeter) };
    } else {
      result[newKey] = input[key];
    }
  }

  return result;
};

const deepExtendObjects = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): Record<string, any> => {
  let merged: Record<string, any> = { ...obj1 };
  for (const key in obj2) {
    if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
      const firstObjectPart = obj1[key] || {};
      merged[key] = {
        ...firstObjectPart,
        ...deepExtendObjects(firstObjectPart, obj2[key])
      };
    } else {
      merged[key] = obj2[key];
    }
  }
  return merged;
};

const getNameInitials = (name: string = '', includeLastName = false): string =>
  includeLastName
    ? String(name)
        .split(' ')
        .map((part) => part.substring(0, 1).toUpperCase())
        .join('')
        .substring(0, 2)
    : String(name).substring(0, 1).toUpperCase();

const getRandomId = (): string =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

const getAppliedClasses = (classList: {
  [k: string]: boolean | undefined;
}): string => {
  return Object.keys(classList)
    .filter((className) => classList[className])
    .join(' ');
};

const getPathParams = (routePathPattern: string): { [key: string]: string } => {
  const urlPath = new URL(location.href).pathname;
  const patternParts = routePathPattern.split('/');
  const urlPathParts = urlPath.split('/');
  const params: { [key: string]: string } = {};
  patternParts.forEach((part, index) => {
    if (part.startsWith(':')) {
      const paramName = part.replace(':', '');
      params[paramName] = urlPathParts[index];
    }
  });
  return params;
};

const cloneObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const arrayToObjectByKey = (list: any[], key: string) => {
  return list.reduce((obj, item) => {
    if (item[key]) {
      obj[item[key]] = item;
    }
    return obj;
  }, {});
};

type GetFullPathWithoutParamsType = {
  paths: string[];
  params: Params;
};

// accept paths as a list of route path definition
// and return the one that matches (if any) the current route
function getFullPathWithoutParams({
  paths,
  params
}: GetFullPathWithoutParamsType): string {
  for (let path of paths) {
    const composedPath = getSlug.environmentById(
      params.environmentId,
      replacePathParams(path as AnyAppPath, params) as EnvironmentPagePath,
      true
    );
    const match = useMatch(() => composedPath);
    if (match()) {
      return getSlug.environmentById(
        undefined,
        replacePathParams(path as AnyAppPath, {}) as EnvironmentPagePath,
        true
      );
    }
  }
  return '';
}

function interpolateVarsInString(str: string, vars: Record<string, string>) {
  let preparedString = str;
  Object.keys(vars).forEach((param) => {
    preparedString = preparedString.replace(`\{${param}\}`, vars[param]);
  });
  return preparedString;
}

function getErrorObjectFromZod(errors: ZodError): Record<string, string> {
  return errors.issues.reduce((errorObj, currentError) => {
    let obj: Record<any, any> = {};
    obj[(currentError.path || []).join('.')] = currentError.message;
    return {
      ...errorObj,
      ...obj
    };
  }, {});
}

const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if (window.onfocus) newWindow?.focus();
  return newWindow;
};

function openLinkInNewTab(link: string) {
  (window as any).open(link, '_blank').focus();
}

function initAppCommons() {
  logger.info(import.meta.env);
  initSentry();
  initPosthog();
  initClarity();
  let appContext: AppContexts;
  try {
    appContext = getAppContext();
  } catch (e) {
    appContext = AppContexts.SCALEKIT_DASHBOARD;
  }
  setGlobalStore('appContext', appContext);
  if (appContext === AppContexts.SCALEKIT_DASHBOARD) {
    setGlobalStore('appFavIcon', `${getCdnBaseUrl()}scalekit-icon.svg`);
  } else if (appContext === AppContexts.CUSTOMER_PORTAL) {
    setGlobalStore(
      'adminPortalEnabledModulesFromQueryParam',
      getPortalFeaturesToDisplayFromQueryParams()
    );
  }
}

function withPortalAuthToken(path: string): string {
  if (
    globalStore.shouldForwardToken &&
    globalStore.appContext === AppContexts.CUSTOMER_PORTAL &&
    globalStore.portalAuthToken
  ) {
    const pathParts = path.split('?');
    const params = new URLSearchParams(
      pathParts.length > 1 ? pathParts[1] : ''
    );
    params.set(
      miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN,
      globalStore.portalAuthToken
    );
    return `${pathParts[0]}?${params.toString()}`;
  }
  return path;
}

type PollAtIntervalParamType = {
  intervalInMs: number;
  maxAttempts: number;
  pollFn: () => Promise<boolean>;
};
const pollAtInterval = async ({
  intervalInMs,
  maxAttempts,
  pollFn
}: PollAtIntervalParamType) => {
  let attempts = 0;
  let success = false;
  while (attempts < maxAttempts) {
    success = await pollFn();
    if (success) {
      break;
    }
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, intervalInMs));
  }
};

function isValidEmail(email: string): boolean {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function getUniqueId(): string {
  return Math.random().toString(36).substring(2, 11);
}

function isLoadedInIframe(): boolean {
  try {
    return window.parent.location.origin !== window.location.origin;
  } catch (e) {
    return true;
  }
}

function getCdnBaseUrl() {
  return import.meta.env.BASE_URL.endsWith('/')
    ? import.meta.env.BASE_URL
    : `${import.meta.env.BASE_URL}/`;
}

function getCamelCase(str: string): string {
  return Object.keys(camelcaseKeys({ [str]: true }))[0];
}

function getSnakeCase(str: string): string {
  return Object.keys(snakecaseKeys({ [str]: true }))[0];
}

function copyTextIntoClipboard(text: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.permissions
        .query({ name: 'clipboard-write' as PermissionName })
        .then((result) => {
          if (result.state === 'granted' || result.state === 'prompt') {
            navigator.clipboard
              .writeText(text)
              .then(() => resolve(true))
              .catch((error) => {
                logger.error('Clipboard write failed:', error);
                reject(error);
              });
          } else {
            logger.error('Clipboard permission not granted.');
            reject(false);
          }
        });
    }
  });
}

const mergeEmailParts = (emailParts: Array<string>) => emailParts.join('@');

const base64Decode: (str: string) => Record<any, any> = (str: string) =>
  jwtDecode(str, { header: true });

const removeParamFromUrl = (param: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, '', url.toString());
};

const getFileExtensionFromName = (name: string) => name.split('.').pop();

const isNotNullOrEmpty = (value: any): boolean =>
  value !== null && value !== undefined && value !== '';

const deepEqual = <T>(obj1: T, obj2: T): boolean => {
  // Check if both values are the same
  if (obj1 === obj2) {
    return true;
  }

  // Check if both are objects (and not null)
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  // Check if they have the same number of keys
  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key in obj1 and compare values in both objects
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

function getQueryParam(param: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param); // Returns the value of the parameter or null if not found
}

function capitalizeStr(str: string): string {
  return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
}

const ENTER_KEY = 'Enter';
function isEnterKeyEvent(e: KeyboardEvent) {
  return e.key === ENTER_KEY;
}

function isValidHexColor(hex: string): boolean {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return hexRegex.test(hex);
}

const getMaskedSecret = (secret: ClientSecretData): string => {
  const maskedPrefix = '*'.repeat(
    CLIENT_SECRET_HASH_LENGTH - (secret.secretSuffix?.length || 0)
  );
  return secret.secretSuffix
    ? maskedPrefix + secret.secretSuffix
    : maskedPrefix;
};

export {
  AppContexts,
  getAppContext,
  getRandomInteger,
  searchInListByKeys,
  flattenObject,
  deepExtendObjects,
  getNameInitials,
  getRandomId,
  getAppliedClasses,
  getPathParams,
  cloneObject,
  arrayToObjectByKey,
  getFullPathWithoutParams,
  interpolateVarsInString,
  getErrorObjectFromZod,
  popupCenter,
  openLinkInNewTab,
  initAppCommons,
  withPortalAuthToken,
  pollAtInterval,
  isValidEmail,
  getUniqueId,
  isLoadedInIframe,
  getCdnBaseUrl,
  getCamelCase,
  getSnakeCase,
  copyTextIntoClipboard,
  mergeEmailParts,
  base64Decode,
  removeParamFromUrl,
  getFileExtensionFromName,
  isNotNullOrEmpty,
  deepEqual,
  getQueryParam,
  capitalizeStr,
  isEnterKeyEvent,
  isValidHexColor,
  getMaskedSecret
};
