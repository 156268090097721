import { JSXElement } from 'solid-js';
import Base from '~/components/Layout/Header/SwitcherButtons/Base.tsx';
import Menu from '~/components/ui/Menu';
import { ChevronsUpDownIcon, SettingsIcon } from 'lucide-solid';
import { Localized } from '~/i18n';
import styles from '../switcher.module.scss';
import { useAppData } from '~/components/AppData';
import { EnvironmentData, EnvironmentTypes } from '~/api';
import { useParams } from '@solidjs/router';
import {
  EnvironmentPagePath,
  getSlug,
  replacePathParams,
  WorkspacePagePath
} from '~/components/AppRouter/utils.ts';
import { getFullPathWithoutParams } from '~/utils/commonUtils.ts';
import { miscConstants } from '~/consts.ts';
import EnvironmentSwitcherItem from '~/components/Layout/Header/SwitcherButtons/EnvironmentSwitcher/EnvironmentSwitcherItem.tsx';
import { ItemType } from '~/components/ui/Menu/Menu.tsx';
import EnvironmentIcon from '~/components/Layout/Header/SwitcherButtons/EnvironmentSwitcher/EnvironmentIcon.tsx';
import Link from '~/components/Link';

export default function EnvironmentSwitcher(): JSXElement {
  const appData = useAppData();
  const params = useParams();

  const matchedRoutePattern = getFullPathWithoutParams({
    paths: Object.values(EnvironmentPagePath),
    params
  });

  function switchEnvironment(envId: string) {
    if (params.environmentId === envId) return;
    localStorage.setItem(
      miscConstants.localStorageKeys.getEnvironmentSelection(),
      envId
    );
    const appPath = replacePathParams(
      matchedRoutePattern as EnvironmentPagePath,
      {
        ...params,
        environmentId: envId
      }
    );
    window.location.assign(`${window.location.origin}${appPath}`);
  }

  const devEnvironments = appData.environments.filter(
    (env) => env.type !== EnvironmentTypes.PRODUCTION
  );

  const prodEnvironments = appData.environments.filter(
    (env) => env.type === EnvironmentTypes.PRODUCTION
  );

  const getEnvironmentList = (
    environments: EnvironmentData[]
  ): Array<ItemType> =>
    environments.map((env) => ({
      label: (
        <EnvironmentSwitcherItem
          onClick={() => switchEnvironment(env.id)}
          isActive={appData.selectedEnvironment?.id === env.id}
          environment={env}
        />
      ),
      class: 'mx-1 hover:bg-transparent h-auto min-w-[280px]'
    }));

  return (
    <Menu
      id={`environment-switcher`}
      containerClass={`max-h-[370px] overflow-y-auto`}
      groups={[
        {
          label: (
            <div
              class={`flex justify-between items-center ${styles.menuCategoryText} mt-2`}
            >
              <Localized translationKey={`top_nav_group_labels.environments`} />

              <Link
                href={getSlug.workspace(
                  WorkspacePagePath.WORKSPACE_MANAGE_ENVIRONMENTS,
                  true
                )}
                class={`flex items-center text-sm font-normal text-fg-muted hover:text-fg-default`}
              >
                <SettingsIcon
                  width={14}
                  class={`mr-0.5`}
                />
                <Localized translationKey={'cta_labels.manage_environments'} />
              </Link>
            </div>
          ),
          hasGroupEndSeparator: false
        },
        {
          class: 'mb-0.5 mt-2',
          items: [
            ...getEnvironmentList(devEnvironments),
            ...getEnvironmentList(prodEnvironments)
          ]
        }
      ]}
    >
      <Base>
        <div class={`flex w-full items-center`}>
          <EnvironmentIcon
            environmentType={
              appData.selectedEnvironment?.type || EnvironmentTypes.DEVELOPMENT
            }
            class={`p-1 mr-2`}
          />
          <span class={`mr-2`}>{appData.selectedEnvironment?.displayName}</span>
          <ChevronsUpDownIcon strokeWidth={1} />
        </div>
      </Base>
    </Menu>
  );
}
