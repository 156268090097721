import {
  createContext,
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  Show,
  untrack,
  useContext
} from 'solid-js';
import {
  getContexts,
  getResourceContextQueryKey,
  retryAuthorizedRequests
} from '~/api';
import { ResourceContextData } from '~/api/types/resourceContextData.ts';
import { createQuery } from '@tanstack/solid-query';
import { useAppData } from '~/components/AppData';
import SkLoader from '~/components/ui/SkLoader';

type ResourceContextDataType = {
  data: ResourceContextData;
  refetch: () => Promise<void>;
};

const ResourceContextDataContext = createContext<ResourceContextDataType>();

type ResourceContextDataProviderProps = {
  children: JSXElement;
};

export default function ResourceContextDataProvider(
  props: ResourceContextDataProviderProps
): JSXElement {
  const merged = mergeProps({}, props);
  const appData = useAppData();

  const [contextData, setContextData] = createSignal<ResourceContextData>({});

  const resourceContextsQuery = createQuery(() => ({
    queryKey: getResourceContextQueryKey(),
    queryFn: async () => {
      return appData.selectedEnvironment?.id
        ? await getContexts({ environmentId: appData.selectedEnvironment.id })
        : {};
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (appData.selectedEnvironment?.id) {
      untrack(() => {
        resourceContextsQuery.refetch();
      });
    }
  });

  createEffect(() => {
    if (resourceContextsQuery.isSuccess && resourceContextsQuery.data) {
      untrack(() => {
        setContextData(resourceContextsQuery.data);
      });
    } else if (resourceContextsQuery.isError) {
      untrack(() => {
        setContextData({});
      });
    }
  });

  return (
    <>
      {(() => {
        const contextVal: ResourceContextDataType = {
          data: contextData(),
          refetch: async () => {
            await resourceContextsQuery.refetch();
          }
        };
        return (
          <Show
            when={appData.selectedEnvironment?.id}
            fallback={merged.children}
          >
            <ResourceContextDataContext.Provider value={contextVal}>
              <Show
                when={!resourceContextsQuery.isPending}
                fallback={<SkLoader />}
              >
                {merged.children}
              </Show>
            </ResourceContextDataContext.Provider>
          </Show>
        );
      })()}
    </>
  );
}

export const useResourceContextData = (): ResourceContextDataType =>
  useContext(ResourceContextDataContext) as ResourceContextDataType;
